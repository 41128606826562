import { useNuxtApp } from 'nuxt/app';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { fetchRunOptionsApi } from '@/apis/launch-settings.api';
import { fetchProductsInfoApi } from '@/apis/products.api';
import { fetchProductReleaseStatusApi } from '@/apis/release-plan.api';
import { PLATFORM } from '@/constants/common.const';
import { PRODUCT_FEATS } from '@/constants/page.const';
import {
  PLAN_STATUS,
  PRODUCT_TYPE,
  PRODUCT_TYPE_DETAIL,
  VERIFY_STATUS
} from '@/constants/products.const';
import { productDetailTypeMapper } from '@/mappers/products.mapper';
import type { RunOptionGroup } from '@/types/launch-settings/launch-settings.type';
import type {
  ProductFeat,
  ProductFeatChild,
  ProductSettingInfo
} from '@/types/product/product-model.type';
import type {
  ProductResponse
} from '@/types/product/product-response.type';
import type { ReleasePlanStatus } from '@/types/release-plan/release-plan.type';
import { getConfigs } from '@/utils/common.util';

export default defineStore('productStore', () => {
  const nuxtApp = useNuxtApp();
  const { locale } = nuxtApp.$i18n as { locale: any };

  const isProductWritable = ref(true);
  const releaseStatusApiCalled = ref(false);
  const releasePlan = ref<ReleasePlanStatus>();
  const isSticky = ref(false);

  const setIsSticky = (value: boolean) => {
    isSticky.value = value;
  };

  const product = ref<ProductResponse | undefined>();
  const productNo = ref<string>('');
  const showProductKey = ref(false);
  const showProductSearch = ref(false);
  const showFeatMenu = ref(true);
  const pageTitles = ref<{ title: string; url: string }[]>([]);

  const platform = ref<string[]>([]);
  const productRunOptions = ref<Array<RunOptionGroup> | undefined>([]);

  const fetchProductsInfo = async (no: string) => {
    productNo.value = no;
    product.value = await fetchProductsInfoApi(no);
  };

  const setPageTitles = (titles: { title: string; url: string }[]) => {
    pageTitles.value = titles;
  };

  const setShowProductKey = (value: boolean) => {
    showProductKey.value = value;
  };

  const setShowProductSearch = (value: boolean) => {
    showProductSearch.value = value;
  };

  const setShowFeatMenu = (value: boolean) => {
    showFeatMenu.value = value;
  };

  const getProductPlatform = async () => {
    if (!product.value) {
      return;
    }
    const runOptions = await fetchRunOptionsApi(product.value?.gameId);
    if (runOptions && runOptions.length > 0) {
      platform.value = [...new Set(runOptions.map((run: RunOptionGroup) => run.type))];
      productRunOptions.value = runOptions;
      return;
    }
    productRunOptions.value = runOptions;
    platform.value = [PLATFORM.PC];
  };

  const isReleased = computed(() => {
    return product.value?.planStatus === PLAN_STATUS.RELEASE;
  });

  const isPreRelease = computed(() => {
    if (product.value) {
      return ([PLAN_STATUS.NONE, PLAN_STATUS.READY, PLAN_STATUS.PAGE_OPEN] as string[]).includes(
        product.value.planStatus
      );
    }

    return false;
  });

  const currentProductDetailType = computed(() => {
    if (!product.value) {
      return '';
    }
    const { productType, demoYn, productDetailType } = product.value;
    return productDetailTypeMapper(productType, demoYn, productDetailType);
  });

  const productFeats = computed(() => {
    return PRODUCT_FEATS.map((productFeat: ProductFeat) => {
      const children = productFeat.children.filter((item: ProductFeatChild) => {
        return item.accessibleProductTypes?.includes(currentProductDetailType.value);
      });
      return {
        ...productFeat,
        children
      };
    }).filter((group: ProductFeat) => group.children.length > 0);
  });

  const gnbProductFeats = computed(() => {
    return productFeats.value
      .map((group: ProductFeat) => {
        const children = group.children.filter((item: ProductFeatChild) => {
          return !item.showPlace || item.showPlace === 'gnb';
        });
        return {
          ...group,
          children
        };
      })
      .filter((group: ProductFeat) => group.children.length > 0);
  });

  const productHomeFeats = computed(() => {
    return productFeats.value
      .map((group: ProductFeat) => {
        const children = group.children.filter((item: ProductFeatChild) => {
          return !item.showPlace || item.showPlace === 'product-home';
        });
        return {
          ...group,
          children
        };
      })
      .filter((group: ProductFeat) => group.children.length > 0);
  });

  const productDetailLabel = computed(() => {
    switch (currentProductDetailType.value) {
      case PRODUCT_TYPE_DETAIL.GAME_BASIC:
        return PRODUCT_TYPE.GAME;
      case PRODUCT_TYPE_DETAIL.GAME_DEMO:
        return PRODUCT_TYPE.DEMO;
      case PRODUCT_TYPE_DETAIL.GAME_DLC:
        return PRODUCT_TYPE.DLC;
      case PRODUCT_TYPE_DETAIL.UTILITY_BASIC:
        return PRODUCT_TYPE.UTILITY;
      case PRODUCT_TYPE_DETAIL.UTILITY_DEMO:
        return PRODUCT_TYPE.DEMO;
      case PRODUCT_TYPE_DETAIL.UTILITY_DLC:
        return PRODUCT_TYPE.DLC;
      case PRODUCT_TYPE_DETAIL.CONTENT_BASIC:
        return PRODUCT_TYPE.CONTENT;
      case PRODUCT_TYPE_DETAIL.CONTENT_DEMO:
        return PRODUCT_TYPE.DEMO;
      default:
        return '';
    }
  });

  const basicInfo = computed(() => {
    const infos = product.value?.infos || [];
    return infos?.find((info: ProductSettingInfo) => info.languageCd === locale.value);
  });

  const productName = computed(() => {
    const infos = product.value?.infos || [];
    return (
      infos?.find((info: ProductSettingInfo) => info.languageCd === locale.value)?.productName || ''
    );
  });

  const isBeforeReview = computed(() => {
    const { planStatus, verifyStatus } = product.value || {};

    return (
      planStatus !== PLAN_STATUS.RELEASE &&
      verifyStatus !== VERIFY_STATUS.REQUEST &&
      verifyStatus !== VERIFY_STATUS.START &&
      verifyStatus !== VERIFY_STATUS.PASS
    );
  });

  const productFeatUrlGenerator = (productFeat: ProductFeatChild) => {
    let host = '';
    if (productFeat.url.includes('http')) {
      return productFeat.url;
    }
    if (!productFeat.internal) {
      const { STUDIO_V2_URL } = getConfigs();
      host = `${STUDIO_V2_URL}/${locale.value}`;
    }
    return `${host}${productFeat.url}`;
  };

  const isMenuDisabled = (item: ProductFeatChild) => {
    const { disableConditions } = item;
    if (!disableConditions || platform.value.includes(PLATFORM.PC)) {
      return false;
    }
    let disableCount = 0;
    for (const pf of platform.value) {
      for (const condition of disableConditions) {
        if (pf === condition.platform && condition.detailType === currentProductDetailType.value) {
          disableCount++;
        }
      }
    }
    return platform.value.length === disableCount;
  };

  const isWebOrMobile = computed(() => {
    return !platform.value.includes(PLATFORM.PC);
  });

  const isVerifyPass = computed(() => {
    return product.value?.verifyStatus === VERIFY_STATUS.PASS;
  });

  const currentPage = computed(() => {
    return releasePlan.value?.page?.current;
  });

  const currentSale = computed(() => {
    return releasePlan.value?.sale?.current;
  });

  const currentBuild = computed(() => {
    return releasePlan.value?.build?.current;
  });

  const isPageBuild = computed(() => {
    return currentPage.value && currentSale.value && (currentBuild.value || isWebOrMobile.value);
  });

  const fetchProductReleaseStatus = async () => {
    if (releaseStatusApiCalled.value) {
      return;
    }
    try {
      const res = await fetchProductReleaseStatusApi(Number(productNo.value));
      releasePlan.value = res;
    } catch (error) {}
    releaseStatusApiCalled.value = true;
  };

  return {
    productNo,
    releasePlan,
    getProductPlatform,
    fetchProductsInfo,
    product,
    currentProductDetailType,
    productDetailLabel,
    productFeatUrlGenerator,
    productName,
    isBeforeReview,
    basicInfo,
    showProductKey,
    showProductSearch,
    showFeatMenu,
    pageTitles,
    setShowProductKey,
    platform,
    isReleased,
    isMenuDisabled,
    productRunOptions,
    gnbProductFeats,
    productHomeFeats,
    isPreRelease,
    setPageTitles,
    setShowProductSearch,
    setShowFeatMenu,
    isProductWritable,
    isSticky,
    setIsSticky,
    fetchProductReleaseStatus,
    isVerifyPass,
    isPageBuild,
    releaseStatusApiCalled
  };
});
