import { useRequest } from '@/composables/useRequest';
import { API_SUCCESS_STATUS_CODE, API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type { RunOptionGroup } from '@/types/launch-settings/launch-settings.type';
import type {
  CheckDRMAndSDKRequest,
  InstallPathAndPcClientSettingsRequest,
  RunOptionRequest
} from '@/types/launch-settings/launch-settings-request.type';
import type {
  CheckDRMAndSDKResponse,
  CheckRegisteredBuildResponse,
  InstallPathAndPcClientSettingsResponse,
  LaunchSettingsStatusResponse,
  RequiredProgramResponse,
  RunOptionResponse
} from '@/types/launch-settings/launch-settings-response.type';
import { camelToSnake } from '@/utils/api.util';
import { replaceEmptyStringToNull } from '@/utils/string.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/display/SGVTN/Get+All+list+required+program
export const fetchRequiredProgramListApi = async (): Promise<
  Array<RequiredProgramResponse> | undefined
> => {
  const { data } = await useRequest<Array<RequiredProgramResponse>>(`${apiUrl}/builds/programs`);
  return data;
};
// https://wiki.smilegate.net/pages/viewpage.action?pageId=450832167
export const fetchInstallPathAndPcClientSettingsApi = async (
  gameId: string
): Promise<InstallPathAndPcClientSettingsResponse | undefined> => {
  const { data } = await useRequest<InstallPathAndPcClientSettingsResponse>(
    `${apiUrl}/builds/run-settings?game_id=${gameId}`
  );
  return data;
};
// https://wiki.smilegate.net/display/SGVTN/Api+get+build+run+option+settings+list+of+game
export const fetchRunOptionsApi = async (
  gameId: string
): Promise<Array<RunOptionGroup> | undefined> => {
  const { data } = await useRequest<RunOptionResponse>(
    `${apiUrl}/builds/run-options?game_id=${gameId}`
  );
  return data?.contents;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SGVTN&title=API+check+build+is+registered+with+game+id
export const checkBuildIsRegisteredByGameIdApi = async (
  gameId: string
): Promise<CheckRegisteredBuildResponse | undefined> => {
  const { data } = await useRequest<CheckRegisteredBuildResponse>(
    `${apiUrl}/builds/game/check?game_id=${gameId}`
  );
  return data;
};

// https://wiki.smilegate.net/display/SGVTN/API+Check+meta+file+release+enable
export const checkDRMAndSDKByGameIdApi = async (
  payload: CheckDRMAndSDKRequest
): Promise<CheckDRMAndSDKResponse | undefined> => {
  const { data } = await useRequest<CheckDRMAndSDKResponse>(`${apiUrl}/builds/meta-release/check`, {
    method: ApiMethod.Post,
    data: camelToSnake(payload)
  });
  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=450832141
export const saveInstallationPathAndPcClientSettingsApi = async (
  payload: InstallPathAndPcClientSettingsRequest
): Promise<string> => {
  const { data } = await useRequest<{ id: string }>(`${apiUrl}/builds/run-settings`, {
    method: ApiMethod.Post,
    data: camelToSnake(replaceEmptyStringToNull(payload)),
    showCommonError: false
  });
  return data?.id || '';
};

// https://wiki.smilegate.net/display/SGVTN/Api+update+build+run+option+settings
export const saveRunOptionApi = async (payload: RunOptionRequest): Promise<string> => {
  const { data } = await useRequest<{ id: string }>(`${apiUrl}/builds/run-options`, {
    method: payload.id ? ApiMethod.Put : ApiMethod.Post,
    data: camelToSnake(replaceEmptyStringToNull(payload)),
    showCommonError: false
  });
  return data?.id || '';
};

// https://wiki.smilegate.net/display/SGVTN/API+delete+build+option
export const deleteRunOptionApi = async (gameId: string, runOptionId: string): Promise<boolean> => {
  const data = await useRequest<{ statusText: string; status: number }>(
    `${apiUrl}/builds/run-options`,
    {
      method: ApiMethod.Delete,
      data: { game_id: gameId, id: runOptionId },
      showCommonError: false
    }
  );
  return data?.status === API_SUCCESS_STATUS_CODE;
};

// https://wiki.smilegate.net/pages/viewpage.action?spaceKey=SGVTN&title=Get+Build+product+release+status
export const fetchLaunchSettingsStatusApi = async (
  gameId: string
): Promise<LaunchSettingsStatusResponse | undefined> => {
  const { data } = await useRequest<LaunchSettingsStatusResponse | undefined>(
    `${apiUrl}/builds/product-release/status?game_id=${gameId}`
  );
  return data;
};
// https://wiki.smilegate.net/display/SGVTN/API+delete+run+settings
export const deleteInstallationPathAndPcClientSettingsApi = async (
  id: string, gameId: string
): Promise<boolean> => {
  const data = await useRequest<{ statusText: string; status: number }>(
    `${apiUrl}/builds/run-settings`,
    {
      method: ApiMethod.Delete,
      data: { id, game_id: gameId },
      showCommonError: false
    }
  );
  return data?.status === API_SUCCESS_STATUS_CODE;
};

// https://wiki.smilegate.net/display/SGVTN/API+delete+build+rating+review
export const deleteBuildAndRatingReViewByProductNoApi = async (
  productNo: number,
  gameId: string
): Promise<boolean> => {
  const data = await useRequest<{ statusText: string; status: number }>(
    `${apiUrl}/games/builds/rating-review`,
    {
      method: ApiMethod.Delete,
      data: { product_no: productNo, game_id: gameId },
      showCommonError: false
    }
  );
  return data?.status === API_SUCCESS_STATUS_CODE;
};

// https://wiki.smilegate.net/display/SGVTN/Update+generate+meta+file+after+apply+live
export const generateMetaFileApi = async (gameId: string): Promise<boolean> => {
  const data = await useRequest<{ statusText: string; status: number }>(`${apiUrl}/builds/generate-meta-file`, {
    method: ApiMethod.Put,
    data: { game_id: gameId }
  });
  return data?.status === API_SUCCESS_STATUS_CODE;
};
